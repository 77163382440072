import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip'
import Moment from 'moment'

const Title = ({ startDate, endDate }) => {
  const start = Moment.unix(startDate.seconds)
  const end = Moment.unix(endDate.seconds)
  const sameMonth = start.isSame(end, 'month')
  return sameMonth ? `Report ${start.format('MMM YY')}` : `Report ${start.format('MMM')} - ${end.format('MMM YY')}`
}

const Subtitle = ({ billCount, invoiceCount }) =>
  `${billCount} bills, ${invoiceCount} invoices`

export const ReportList = ({ classes, reports, onSelect, onAdd }) => (
  <List
    component="nav"
    subheader={
      <ListSubheader component="div" className={classes.header}>
        {reports.length > 0 ? 'Reports' : 'No Report Available'}
        <Tooltip title="New Report" aria-label="Create new report">
          <IconButton color="primary" onClick={() => onAdd()}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </ListSubheader>
    }
    className={classes.root}>
    {reports.map(report => (
      <ListItem key={report.id} button onClick={() => onSelect(report)}>
        <ListItemText primary={Title(report)} secondary={Subtitle(report)} />
      </ListItem>
    ))}
  </List>
)

ReportList.propTypes = {
  classes: PropTypes.object,
  reports: PropTypes.array,
  onSelect: PropTypes.func,
  onAdd: PropTypes.func
}

export default ReportList
