import {
  CLIENT_COLLECTION,
  REPORT_COLLECTION,
  INVITE_COLLECTION
} from 'constants/collections'

export const onCreateReport = ({
  firestore,
  accountant,
  client,
  onReportSelect,
  showError,
  onNewReportDialogClose,
  toggleNewReportLoader
}) => async ({
  startDate,
  endDate
}) => {
  const { userId } = client
  toggleNewReportLoader()
  try {
    let reportRef = await firestore.add(
      {
        collection: REPORT_COLLECTION,
      },
      {
        accountantId: accountant.id,
        userId,
        startDate: startDate,
        endDate: endDate,
        invoiceCount: 0,
        billCount: 0,
        filed: false
      }
    )
    onNewReportDialogClose()
    toggleNewReportLoader()
    onReportSelect({ id: reportRef.id} )
  } catch (err) {
    toggleNewReportLoader()
    showError(err.message || err)
  }
}

export const onCreateInvite = ({
  firebase,
  firestore,
  accountant,
  showError,
  showSuccess,
  onClientInviteDialogClose,
  clientInviteName,
  clientInvitePhoneNumber,
  toggleClientInviteLoader
}) => async ({ sendSMS }) => {
  toggleClientInviteLoader()
  try {
    const phoneNumber = clientInvitePhoneNumber.split(" ").join("")
    await firestore.collection(INVITE_COLLECTION)
      .doc(phoneNumber)
      .set({
        accountantId: accountant.id,
        name: clientInviteName,
        phoneNumber,
        claimed: false
      },
      { merge: true }
      )
    const clientSnapshot = await firestore.collection(CLIENT_COLLECTION)
      .where("accountantId", "==", accountant.id)
      .where("phoneNumber", "==", phoneNumber)
      .get()
    if (clientSnapshot.empty) {
      await firestore.add(
        {
          collection: CLIENT_COLLECTION,
        },
        {
          accountantId: accountant.id,
          name: clientInviteName,
          phoneNumber,
          createdAt: firestore.FieldValue.serverTimestamp(),
          updatedAt: firestore.FieldValue.serverTimestamp()
        }
      )
    } else {
      clientSnapshot.forEach(document => {
        document.ref.update({
          name: clientInviteName,
          updatedAt: firestore.FieldValue.serverTimestamp()
        })
      })
    }
    onClientInviteDialogClose()
    showSuccess('Invitation sent')
    if (sendSMS) {
      const sendMessage = firebase.functions().httpsCallable('sendSMS')
      const from = accountant.name
      const to = phoneNumber.replace('+','')
      const link = 'https://apps.apple.com/il/app/billeaz/id1457944718'
      const text = `${from} invites you to use the Billeaz app! Download from the Apple Store here: ${link}`
      console.log(text)
      sendMessage({
        from,
        to,
        text
      })
    }
  } catch (err) {
    showError(err.message || err)
  }
}
