import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { firestoreConnect } from 'react-redux-firebase'
import { firestoreOrderedSelector } from 'redux-firestore'
import { withStateHandlers, withHandlers, pure, lifecycle } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { withNotifications } from 'modules/notification'
import { UserIsAuthenticated } from 'utils/router'
import { actions as dashboardActions } from 'store/dashboard'
import {
  ACCOUNTANT_COLLECTION,
  CLIENT_COLLECTION,
  REPORT_COLLECTION
} from 'constants/collections'
import { REPORT_PATH } from 'constants/paths'
import styles from './DashboardLayout.styles'
import * as handlers from './DashboardLayout.handlers'

const now = new Date()

const getAccountantListQuery = ({ uid }) => ({
  collection: ACCOUNTANT_COLLECTION,
  where: [['userId', '==', uid]]
})

const getClientListQuery = ({ accountantId }) => ({
  collection: CLIENT_COLLECTION,
  where: [['accountantId', '==', accountantId]]
})

const getReportListQuery = ({ userId, accountantId }) => ({
  collection: REPORT_COLLECTION,
  where: [
    ['userId', '==', userId],
    ['accountantId', '==', accountantId]
  ],
  orderBy: ['startDate', 'desc']
})

const mapStateToProps = (state) => {
  const {
    firebase: {
      auth: { uid }
    },
    dashboard: { accountant, client, reportId }
  } = state
  const accountants = firestoreOrderedSelector(getAccountantListQuery({ uid }))(
    state
  )
  let props = {
    uid,
    accountants,
    accountant,
    client,
    reportId
  }
  if (accountant !== undefined) {
    const accountantId = accountant.id
    const clients = firestoreOrderedSelector(
      getClientListQuery({ accountantId })
    )(state)
    props = { ...props, clients }
  }
  if (
    accountant !== undefined &&
    client !== undefined &&
    client.userId !== undefined
  ) {
    const accountantId = accountant.id
    const { userId } = client
    const reports = firestoreOrderedSelector(
      getReportListQuery({ userId, accountantId })
    )(state)
    props = { ...props, reports }
    if (reportId !== undefined && reports !== undefined) {
      const report = reports.find((item) => item.id === reportId)
      props = { ...props, report }
    }
  }
  return props
}

const mapDispatchToProps = {
  accountantChange: dashboardActions.accountantChange,
  clientChange: dashboardActions.clientChange,
  reportChange: dashboardActions.reportChange
}

export default compose(
  withRouter,
  // Wait for auth to be loaded and not empty before going further. Display loader while waiting
  UserIsAuthenticated,
  // Add props.showError and props.showSuccess
  withNotifications,
  // Add props.uid, props.accountants, props.accountant, props.clients, props.client, props.reports
  // Add props.accountantChange, props.clientChange and props.reportChange
  connect(mapStateToProps, mapDispatchToProps),
  // Create accountant, client and report listener
  firestoreConnect(({ uid, accountant, client }) => {
    const listeners = [getAccountantListQuery({ uid })]
    if (accountant !== undefined) {
      const accountantId = accountant.id
      listeners.push(getClientListQuery({ accountantId }))
    }
    if (
      accountant !== undefined &&
      client !== undefined &&
      client.userId !== undefined
    ) {
      const { userId } = client
      const accountantId = accountant.id
      listeners.push(getReportListQuery({ userId, accountantId }))
    }
    return listeners
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        accountant,
        accountants,
        accountantChange,
        clients,
        client,
        clientChange,
        reports,
        reportId,
        reportChange
      } = this.props
      if (
        accountant === undefined &&
        accountants !== undefined &&
        accountants.length > 0
      ) {
        accountantChange(accountants[0])
      }
      if (client === undefined && clients !== undefined && clients.length > 0) {
        const client = clients.find(({ userId }) => userId !== undefined)
        clientChange(client)
      }
      if (
        reportId === undefined &&
        reports !== undefined &&
        reports.length > 0
      ) {
        const report = reports.find(({ startDate }) => startDate.toDate() < now)
        if (report) {
          reportChange(report.id)
        } else {
          reportChange(reports[0].id)
        }
      }
    }
  }),
  withStateHandlers(
    // Setup initial state
    () => ({
      open: true,
      openNewReportDialog: false,
      showNewReportLoader: false,
      newReportAnchorEl: null,
      openClientInviteDialog: false,
      clientInviteRefreshState: false,
      showClientInviteLoader: false,
      clientInviteAnchorEl: null,
      clientInviteName: null,
      clientInvitePhoneNumber: null
    }),
    // Add state handlers as props, passsed in (state, props)
    {
      toggleDrawer: ({ open }) => () => ({
        open: !open
      }),
      onClientSelect: (state, { clientChange, history }) => (client) => {
        history.push(REPORT_PATH)
        clientChange(client)
        return { client }
      },
      openReportList: () => (currentTarget) => ({
        newReportAnchorEl: currentTarget
      }),
      closeReportList: () => () => ({
        newReportAnchorEl: null
      }),
      onReportSelect: (state, { reportChange, history }) => ({ id }) => {
        history.push(REPORT_PATH)
        reportChange(id)
        return { newReportAnchorEl: null }
      },
      onNewReportDialogOpen: () => () => ({
        openNewReportDialog: true,
        newReportAnchorEl: null
      }),
      onNewReportDialogClose: () => () => ({
        openNewReportDialog: false
      }),
      toggleNewReportLoader: ({ showNewReportLoader }) => () => ({
        showNewReportLoader: !showNewReportLoader
      }),
      onClientInviteDialogOpen: () => () => ({
        openClientInviteDialog: true,
        clientInviteAnchorEl: null
      }),
      onClientInviteDialogClose: () => () => ({
        openClientInviteDialog: false,
        clientInviteName: null,
        clientInvitePhoneNumber: null,
        showClientInviteLoader: false
      }),
      toggleClientInviteLoader: ({ showClientInviteLoader }) => () => ({
        showClientInviteLoader: !showClientInviteLoader
      }),
      onClientInviteNameChange: () => ({ name }) => ({
        clientInviteName: name
      }),
      onClientInvitePhoneNumberChange: () => ({ phoneNumber }) => ({
        clientInvitePhoneNumber: phoneNumber
      })
    }
  ),
  withHandlers(handlers),
  withStyles(styles, { withTheme: true }),
  pure // shallow equals comparison on props (prevent unessesary re-renders)
)
