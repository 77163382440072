import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import Menu from '@material-ui/core/Menu'
import { Notifications } from 'modules/notification'
import ClientList from './components/ClientList'
import ReportList from './components/ReportList'
import AccountMenu from './components/AccountMenu'
import NewReportDialog from './components/NewReportDialog'
import InviteClientDialog from './components/InviteClientDialog'

export const DashboardLayout = ({
  children,
  classes,
  open,
  toggleDrawer,
  clients,
  client,
  onClientSelect,
  reports,
  onReportSelect,
  onCreateReport,
  openNewReportDialog,
  onNewReportDialogOpen,
  onNewReportDialogClose,
  newReportAnchorEl,
  openReportList,
  closeReportList,
  showNewReportLoader,
  onCreateInvite,
  openClientInviteDialog,
  onClientInviteDialogOpen,
  onClientInviteDialogClose,
  clientInviteAnchorEl,
  showClientInviteLoader,
  onClientInviteNameChange,
  onClientInvitePhoneNumberChange,
  clientInviteName,
  clientInvitePhoneNumber
}) => (
  <div className={classes.root}>
    <CssBaseline />
    <AppBar
      position="absolute"
      className={classnames(classes.appBar, open && classes.appBarShift)}>
      <Toolbar disableGutters={!open} className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={() => toggleDrawer()}
          className={classnames(
            classes.menuButton,
            open && classes.menuButtonHidden
          )}>
          <MenuIcon />
        </IconButton>
        {client !== undefined && (
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}>
            {`Client: ${client.name}`}
          </Typography>
        )}
        {client !== undefined && (
          <div>
            <Button
              color="inherit"
              onClick={event => openReportList(event.currentTarget)}
              aria-haspopup="true"
              aria-label="Show Report List">
              Reports
              {newReportAnchorEl ? (
                <ExpandLessIcon className={classes.expand} />
              ) : (
                <ExpandMoreIcon className={classes.expand} />
              )}
            </Button>
            <Menu
              id="menu-appbar"
              newReportAnchorEl={newReportAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              PaperProps={{
                style: {
                  maxHeight: 400,
                  width: 300
                }
              }}
              open={Boolean(newReportAnchorEl)}
              onClose={closeReportList}>
              {reports && (
                <ReportList reports={reports} onSelect={onReportSelect} onAdd={onNewReportDialogOpen} />
              )}
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
    <Drawer
      variant="permanent"
      classes={{
        paper: classnames(
          classes.drawerPaper,
          !open && classes.drawerPaperClose
        )
      }}
      open={open}>
      <div className={classes.toolbarIcon}>
        <AccountMenu />
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      {clients && <ClientList clients={clients} onSelect={onClientSelect} onInvite={onClientInviteDialogOpen} />}
    </Drawer>
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <div>{children}</div>
      <Notifications />
    </main>
    {client !== undefined && (
      <NewReportDialog
        open={openNewReportDialog}
        onClose={onNewReportDialogClose}
        client={client}
        showLoader={showNewReportLoader}
        onCreateReport={onCreateReport}
      />
    )}
    <InviteClientDialog
      open={openClientInviteDialog}
      onClose={onClientInviteDialogClose}
      showLoader={showClientInviteLoader}
      onCreateInvite={onCreateInvite}
      onNameChange={onClientInviteNameChange}
      onPhoneNumberChange={onClientInvitePhoneNumberChange}
      name={clientInviteName}
      phoneNumber={clientInvitePhoneNumber}
    />
  </div>
)

DashboardLayout.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.object,
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  clients: PropTypes.array,
  client: PropTypes.object,
  onClientSelect: PropTypes.func,
  reports: PropTypes.array,
  onReportSelect: PropTypes.func,
  onCreateReport: PropTypes.func,
  openNewReportDialog: PropTypes.bool,
  onNewReportDialogOpen: PropTypes.func,
  onNewReportDialogClose: PropTypes.func,
  newReportAnchorEl: PropTypes.object,
  openClientInviteDialog: PropTypes.bool,
  newInviteRefreshState: PropTypes.bool,
  onClientInviteDialogOpen: PropTypes.func,
  onClientInviteDialogClose: PropTypes.func,
  onClientInviteNameChange: PropTypes.func,
  clientInviteName: PropTypes.string,
  clientInvitePhoneNumber: PropTypes.string,
  onClientInvitePhoneNumberChange:  PropTypes.func,
  showClientInviteLoader: PropTypes.bool,
  clientInviteAnchorEl: PropTypes.object,
  onCreateInvite: PropTypes.func,
  openReportList: PropTypes.func,
  closeReportList: PropTypes.func,
  showNewReportLoader: PropTypes.bool
}

export default DashboardLayout
