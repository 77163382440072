import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import ScheduleIcon from '@material-ui/icons/Schedule'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { orange } from '@material-ui/core/colors';

const isPendingClaim = ({ userId }) => userId === undefined

const sortByUpdatedDate = clients => clients.sort((client1, client2) => {
  if (!client1.updatedAt || !client2.updatedAt) {
    return -1
  }
  const date1 = client1.updatedAt.toDate();
  const date2 = client2.updatedAt.toDate();
  if (date1 > date2) {
    return -1
  } else {
    return 1
  }
})

export const ClientList = ({ classes, clients, onSelect, onInvite }) => (
  <List
    component="nav"
    subheader={
      <ListSubheader component="div" className={classes.header}>
        {'Clients'}
        <Tooltip title="New Client" aria-label="Invite new client">
          <IconButton color="primary" onClick={() => onInvite()}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </ListSubheader>
    }
    className={classes.root}>
    {clients && sortByUpdatedDate(clients).map(client => {
        return isPendingClaim(client) ? (
        <ListItem key={client.id}>
          <ListItemIcon>
            <ScheduleIcon style={{ color: orange[500] }} />
          </ListItemIcon>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {`${client.name} - Invited`}
                </Typography>
              </React.Fragment>
            }
            secondary={client.phoneNumber}
          />
        </ListItem>
      ) : (
        <ListItem key={client.id} button onClick={() => onSelect(client)}>
          <ListItemText primary={client.name} />
        </ListItem>
      )
    })}
  </List>
)

ClientList.propTypes = {
  classes: PropTypes.object,
  clients: PropTypes.array,
  onSelect: PropTypes.func,
  onInvite: PropTypes.func
}

export default ClientList
