// ------------------------------------
// Constants
// ------------------------------------
export const CLIENT_CHANGE = 'CLIENT_CHANGE'
export const ACCOUNTANT_CHANGE = 'ACCOUNTANT_CHANGE'
export const REPORT_CHANGE = 'REPORT_CHANGE'

// ------------------------------------
// Actions
// ------------------------------------
export function clientChange(client) {
  return {
    type: CLIENT_CHANGE,
    client
  }
}

export function accountantChange(accountant) {
  return {
    type: ACCOUNTANT_CHANGE,
    accountant
  }
}

export function reportChange(reportId) {
  return {
    type: REPORT_CHANGE,
    reportId
  }
}

export const actions = {
  clientChange,
  accountantChange,
  reportChange
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CLIENT_CHANGE]: (state, action) => {
    return {
      ...state,
      client: action.client,
      reportId: undefined
    }
  },
  [ACCOUNTANT_CHANGE]: (state, action) => {
    return {
      ...state,
      accountant: action.accountant,
      client: undefined,
      reportId: undefined
    }
  },
  [REPORT_CHANGE]: (state, action) => {
    return {
      ...state,
      reportId: action.reportId
    }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  accountant: undefined,
  client: undefined,
  reportId: undefined
}
export default function dashboardReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
