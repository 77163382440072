import React, { useState, useEffect } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { makeStyles } from '@material-ui/core/styles'
import { ACCOUNT_PATH } from 'constants/paths'
import { USER_AVATAR_REF } from 'constants/storage'
import styles from './AccountMenu.styles'

const useStyles = makeStyles(styles)

function AccountMenu() {
  const classes = useStyles()
  const [anchorEl, setMenu] = useState(null)
  const [avatarUrl, setAvatarUrl] = useState(null)
  const history = useHistory()
  const firebase = useFirebase()
  const { displayName, avatar } = useSelector(({ firebase: { profile } }) => profile)

  useEffect(() => {
    if (avatar) {
      fetchAvatarDownloadUrl()
    }
  })

  async function fetchAvatarDownloadUrl() {
    const imagePath = `${USER_AVATAR_REF}/${avatar}`
    const storageRef = firebase.storage().ref()
    const imageRef = storageRef.child(imagePath)
    try {
      const url = await imageRef.getDownloadURL()
      setAvatarUrl(url)
    } catch (err) {}
  }

  function closeAccountMenu(e) {
    setMenu(null)
  }
  function handleMenu(e) {
    setMenu(e.target)
  }
  function handleLogout() {
    closeAccountMenu()
    // redirect to '/' handled by UserIsAuthenticated HOC
    return firebase.logout()
  }
  function goToAccount() {
    closeAccountMenu()
    history.push(ACCOUNT_PATH)
  }

  return (
    <>
      <IconButton
        aria-owns={anchorEl ? 'menu-appbar' : null}
        aria-haspopup="true"
        onClick={handleMenu}
        classes={{ root: classes.buttonRoot }}>
        {avatarUrl ? (
          <Avatar alt={displayName} src={avatarUrl} />
        ) : (
          <AccountCircle />
        )}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={closeAccountMenu}>
        <MenuItem onClick={goToAccount}>Account</MenuItem>
        <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
      </Menu>
    </>
  )
}

export default AccountMenu
