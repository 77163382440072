/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */

export const env = 'dev'

// Config for firebase
export const firebase = {
  apiKey: 'AIzaSyC2CwXKJe8lDfVpKYRyyfZC_I2HtpU9NFo',
  authDomain: 'expenses-5d11c.firebaseapp.com',
  databaseURL: 'https://expenses-5d11c.firebaseio.com',
  projectId: 'expenses-5d11c',
  storageBucket: 'expenses-5d11c.appspot.com',
  messagingSenderId: '93780544031',
  appId: '1:93780544031:web:e76d4ee1ff70bd20cb232a'
}

export const publicVapidKey = 'BGszj37xGcmPeJnB9c6EKWOPeEPbKVO5U_JI9N57XJ1ogdUQWFTXUX50RDLmKnWS38LwPS-r3ZgJyd6DcuiRwug'
