import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import MuiPhoneNumber from 'material-ui-phone-number'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export const InviteClientDialog = ({
  classes,
  open,
  showLoader,
  name,
  handleNameChange,
  isButtonClicked,
  nameErrorMessage,
  handlePhoneChange,
  phoneErrorMessage,
  onInvite,
  onClose,
  handleSMSChange,
  smsChecked
}) => (
  <Dialog onClose={onClose} aria-labelledby="invite-client-dialog" open={open} keepMounted={false}>
    <DialogTitle id="new-quiz-dialog">New Client</DialogTitle>
    <DialogContent>
      <FormControl margin="normal" required fullWidth>
        <InputLabel htmlFor="name">Name</InputLabel>
        <Input
          id="name"
          type="text"
          autoFocus
          value={name || ''}
          onChange={handleNameChange}
        />
        {isButtonClicked && nameErrorMessage && (
          <FormHelperText error>{nameErrorMessage}</FormHelperText>
        )}
      </FormControl>
      <FormControl margin="normal" required fullWidth>
        <MuiPhoneNumber
          defaultCountry={'il'}
          onChange={handlePhoneChange}
        />
        {isButtonClicked && phoneErrorMessage && (
          <FormHelperText error>{phoneErrorMessage}</FormHelperText>
        )}
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={smsChecked}
            onChange={handleSMSChange}
            color="primary"
          />
        }
        label="Send SMS"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <div className={classes.wrapper}>
        <Button
          onClick={() => onInvite()}
          color="primary"
          autoFocus
          disabled={showLoader}>
          Send Invite
        </Button>
        {showLoader && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </DialogActions>
  </Dialog>
)

InviteClientDialog.propTypes = {
  classes: PropTypes.object,
  name: PropTypes.string,
  open: PropTypes.bool,
  showLoader: PropTypes.bool,
  handleNameChange: PropTypes.func,
  nameErrorMessage: PropTypes.string,
  handlePhoneChange: PropTypes.func,
  phoneErrorMessage: PropTypes.string,
  isButtonClicked: PropTypes.bool,
  onClose: PropTypes.func,
  onInvite: PropTypes.func,
  handleSMSChange: PropTypes.func,
  smsChecked: PropTypes.bool
}

export default InviteClientDialog
