import { compose } from 'redux'
import { withStateHandlers, pure } from 'recompose'
import { withStyles } from '@material-ui/core'
import styles from './NewReportDialog.styles'

const startMonth = () => {
  let today = new Date()
  return new Date(today.getFullYear(), today.getMonth(), 1)
}

const endMonth = () => {
  let today = new Date()
  return new Date(today.getFullYear(), today.getMonth() + 1, 0)
}

export default compose(
  withStateHandlers(
    // Setup initial state from props
    () => ({
      startDate: startMonth(),
      endDate: endMonth()
    }),
    // Add state handlers as props, passsed in (state, props)
    {
      handleStartDate: () => date => ({
        startDate: date
      }),
      handleEndDate: () => date => ({
        endDate: date
      })
    }
  ),
  withStyles(styles, { withTheme: true }),
  pure // shallow equals comparison on props (prevent unessesary re-renders)
)
