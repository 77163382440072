import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

export const NewReportDialog = ({
  classes,
  open,
  showLoader,
  client,
  onCreateReport,
  onClose,
  startDate,
  endDate,
  handleStartDate,
  handleEndDate
}) => (
  <Dialog
    onClose={onClose}
    aria-labelledby="create-report-dialog"
    open={open}
    fullWidth
    maxWidth={`sm`}>
    <DialogTitle id="create-report-dialog">{`New Report for ${client.name}`}</DialogTitle>
    <DialogContent>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
          <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="start-date-picker"
              label="Start Date"
              value={startDate}
              onChange={handleStartDate}
              KeyboardButtonProps={{
                'aria-label': 'change start date',
              }}
            />
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="end-date-picker"
                label="End Date"
                value={endDate}
                onChange={handleEndDate}
                KeyboardButtonProps={{
                  'aria-label': 'change end date',
                }}
              />
          </Grid>
      </MuiPickersUtilsProvider>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <div className={classes.wrapper}>
        <Button
          onClick={() => onCreateReport({
            startDate: startDate,
            endDate: endDate
          })}
          color="primary"
          autoFocus
          disabled={showLoader}
          >
          Create
        </Button>
        {showLoader && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </DialogActions>
  </Dialog>
)

NewReportDialog.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  showLoader: PropTypes.bool,
  client: PropTypes.object,
  onClose: PropTypes.func,
  onCreateReport: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  handleStartDate: PropTypes.func,
  handleEndDate: PropTypes.func
}

export default NewReportDialog
