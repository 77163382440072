import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import { REPORT_PATH, LOGIN_PATH } from 'constants/paths'
import styles from './HomePage.styles'
import logo from 'static/logo.svg';

const useStyles = makeStyles(styles)

function Home() {
  const classes = useStyles()
  const history = useHistory()

  // Get auth from redux state
  const auth = useSelector(({ firebase }) => firebase.auth)
  const authDoesNotExist = isLoaded(auth) && isEmpty(auth)
  const authExists = isLoaded(auth) && !isEmpty(auth)

  if (authDoesNotExist) {
    history.push(LOGIN_PATH)
  }

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Avatar className={classes.avatar}>
          <img src={logo} alt="logo" />
        </Avatar>
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome To Billeaz
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          The communication platform for accountants
        </Typography>
        {authExists && (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            component={Link}
            to={REPORT_PATH}
            data-test="sign-in">
            Go To Console
          </Button>
        )}
      </div>
    </div>
  )
}

export default Home
