import { compose } from 'redux'
import { withHandlers, withStateHandlers, pure } from 'recompose'
import { withStyles } from '@material-ui/core'
import { trimStart } from 'lodash'
import styles from './InviteClientDialog.styles'
import { required } from 'utils/form'
import { isValidPhoneNumber } from 'react-phone-number-input'

export default compose(
  withStateHandlers(
    // Setup initial state from props
    () => ({
      isButtonClicked: false,
      nameErrorMessage: 'Required',
      phoneErrorMessage: 'Invalid Phone Number',
      smsChecked: true
    }),
    // Add state handlers as props, passsed in (state, props)
    {
      handleNameChange: (_, { onNameChange }) => event => {
        const name = trimStart(event.target.value)
        onNameChange({ name })
        const nameErrorMessage = required(name)
        return {
          nameErrorMessage,
          isButtonClicked: false
        }
      },
      handlePhoneChange: (_, { onPhoneNumberChange }) => phoneNumber => {
        onPhoneNumberChange({ phoneNumber })
        const phoneErrorMessage = isValidPhoneNumber(phoneNumber) ? undefined : 'Invalid Phone Number'
        return {
          phoneErrorMessage,
          isButtonClicked: false
        }
      },
      toggleButtonClicked: ({ isButtonClicked }) => () => ({
        isButtonClicked: !isButtonClicked
      }),
      setNameErrorMessage: () => ({ nameErrorMessage }) => ({
        nameErrorMessage
      }),
      setPhoneNumberErrorMessage: () => ({ phoneErrorMessage }) => ({
        phoneErrorMessage
      }),
      handleSMSChange: ({ smsChecked }) => () => ({
        smsChecked: !smsChecked
      })
    }
  ),
  withHandlers({
    onInvite: ({
      onCreateInvite,
      toggleButtonClicked,
      setNameErrorMessage,
      setPhoneNumberErrorMessage,
      name,
      phoneNumber,
      smsChecked
    }) => () => {
      toggleButtonClicked()
      const nameErrorMessage = required(name)
      setNameErrorMessage({ nameErrorMessage })
      const phoneErrorMessage = isValidPhoneNumber(phoneNumber || '') ? undefined : 'Invalid Phone Number'
      setPhoneNumberErrorMessage({ phoneErrorMessage })
      if (!nameErrorMessage && !phoneErrorMessage) {
        onCreateInvite({ sendSMS: smsChecked })
      }
    }
  }),
  withStyles(styles, { withTheme: true }),
  pure // shallow equals comparison on props (prevent unessesary re-renders)
)
