import { compose, withHandlers } from 'recompose'
import { withFirebase } from 'react-redux-firebase'
import * as actions from '../actions'

const withNotifications = compose(
  withFirebase,
  withHandlers({
    showError: ({ dispatch }) => err => actions.showError(err)(dispatch),
    showSuccess: ({ dispatch }) => err => actions.showSuccess(err)(dispatch),
    dismissNotification: ({ dispatch }) => id =>
      actions.dismissNotification(id)(dispatch)
  })
)

export default withNotifications
