import React from 'react'
import { Switch, Route } from 'react-router-dom'
import CoreLayout from '../layouts/CoreLayout'
import DashboardLayout from '../layouts/DashboardLayout'
import Home from './Home'
import PrivacyPolicyRoute from './PrivacyPolicy'
import LoginRoute from './Login'
import ReportRoute from './Report'
import AccountRoute from './Account'
import NotFoundRoute from './NotFound'

export default function createRoutes(store) {
  return (
      <Switch>
        <Route exact path={[Home.path, LoginRoute.path, PrivacyPolicyRoute.path]}>
          <CoreLayout>
            <Switch>
              <Route exact path={Home.path} component={() => <Home.component />} />
              {[
                LoginRoute,
                PrivacyPolicyRoute
              ].map((settings, index) => (
                <Route key={`Route-${index}`} {...settings} />
              ))}
            </Switch>
          </CoreLayout>
        </Route>
        <Route path={[AccountRoute.path, ReportRoute.path]}>
          <DashboardLayout>
            <Switch>
              {[
                AccountRoute,
                ReportRoute
              ].map((settings, index) => (
                  <Route key={`Route-${index}`} {...settings} />
              ))}
            </Switch>
          </DashboardLayout>
        </Route>
        <CoreLayout>
          <Route component={NotFoundRoute.component} />
        </CoreLayout>
      </Switch>
  )
}
