export default theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
})
